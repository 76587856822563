import React, { useState } from 'react';
import { graphql } from 'gatsby';
import { navigate } from 'gatsby-link';
import styled from 'styled-components';
import ImagesList from '../components/ImagesList';

const StyledBenefitsWrapper = styled.div`
  margin-top: 150px;
`;

const StyledPageTitle = styled.h1`
  font-weight: ${({ theme }) => theme.font.weight.regular};
  font-size: ${({ theme }) => theme.font.size.s};
  text-align: center;
  text-transform: uppercase;
  font-family: 'Dancing Script', cursive;

  ${({ theme }) => theme.mq.desktop} {
    font-size: ${({ theme }) => theme.font.size.m};
  }
`;

const Filter = styled.span`
  text-decoration: none;
  list-style: none;
  color: ${({ theme }) => theme.black};
  position: relative;
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  margin: 10px 40px;
  font-size: ${({ theme }) => theme.font.size.s};
  letter-spacing: 1px;
  cursor: pointer;
  font-family: 'Dancing Script', cursive;

  ${({ theme }) => theme.mq.desktop} {
    font-size: ${({ theme }) => theme.font.size.m};
  }

  :hover:before {
    visibility: visible;
    width: 100%;
  }

  ::before {
    content: '';
    position: absolute;
    width: 0;
    height: 1px;
    bottom: 0;
    left: 0;
    background-color: ${({ theme }) => theme.black};
    visibility: hidden;
    transition: all 0.3s ease-in-out;
  }

  &.active {
    ::before {
      content: '';
      position: absolute;
      height: 1px;
      bottom: 0;
      left: 0;
      background-color: ${({ theme }) => theme.black};
      visibility: visible;
      transition: all 0.3s ease-in-out;
      width: 100%;
    }
  }
`;

const FiltersWrapper = styled.div`
  padding: 2rem;
  text-align: center;
  margin: 0 auto;

  ${({ theme }) => theme.mq.desktop} {
    padding: 2rem 6rem 6rem 6rem;
  }
`;

function isGalleryType(galleryTypes, title) {
  return galleryTypes.find((type) => type.title === title);
}

export default function GalleryPage({ data }) {
  const [activeImageType, setActiveImageType] = useState(
    data.singleOffer.title
  );

  if (activeImageType === undefined) {
    setActiveImageType(data.singleOffer.title);
  }

  const images = data.images.nodes.filter(
    (img) =>
      isGalleryType(img.imageType, activeImageType) || activeImageType === 'all'
  );

  const allOfferTypes = data.allOffers;
  return (
    <>
      <StyledBenefitsWrapper>
        <StyledPageTitle>Galeria</StyledPageTitle>
      </StyledBenefitsWrapper>
      <FiltersWrapper>
        {allOfferTypes.nodes.map((type) => (
          <Filter
            key={type.id}
            className={type.title === activeImageType ? 'active' : ''}
            onClick={() => navigate(`/galeria/${type.slug.current}`)}
          >
            {type.title}
          </Filter>
        ))}
      </FiltersWrapper>
      <div>{images.length > 0 ? <ImagesList images={images} /> : ''}</div>
    </>
  );
}

export const query = graphql`
  query SingleGalleryQuery($slug: String!) {
    singleOffer: sanityOffer(slug: { current: { eq: $slug } }) {
      image {
        asset {
            gatsbyImageData(fit: FILLMAX, placeholder: BLURRED)
        }
      }
      id
      listOfOffers
      title
      headline
      description
    }
    allOffers: allSanityOffer {
      nodes {
        id
        title
        slug {
          current
        }
      }
    }
    images: allSanityGallery {
      nodes {
        id
        name
        slug {
          current
        }
        imageType {
          id
          title
        }
        image {
          asset {
            id
            gatsbyImageData(fit: FILLMAX, placeholder: BLURRED)
          }
        }
      }
    }
  }
`;
