import React, { useEffect, useState } from 'react';
import { GatsbyImage } from "gatsby-plugin-image";
import styled from 'styled-components';

const ImageGridStyles = styled.div`
  width: 100%;
  background: white;
  position: relative;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-template-rows: 1fr;
  grid-gap: 2px;
`;

const SingleImageLink = styled.a`
  text-decoration: none;
`;

const SingleImageWrapper = styled.div`
  position: relative;
  overflow: hidden;
  cursor: pointer;
`;

const StyledImage = styled(GatsbyImage)`
  width: 100%;
  height: 30rem;
  object-fit: cover;
  opacity: 0.9;
  transition: opacity 0.3s;

  :hover {
    transition: opacity 0.3s;
    opacity: 1;
  }
`;

const LightboxModal = styled.div`
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.8);
  opacity: ${(props) => (props.visible ? '1' : '0')};
  visibility: ${(props) => (props.visible ? 'visible' : 'hidden')};
  z-index: 9999;
  transition: opacity 0.5s linear;
`;

const LightboxContent = styled.div`
  margin: 15px;
  max-width: 700px;
  width: 100%;
`;

const CloseLink = styled.a`
  top: 1%;
  right: 1%;
  position: fixed;
  visibility: visible;
  color: white;
  font-size: 36px;
  text-decoration: none;
  cursor: pointer;

  background-color: transparent;
  background-repeat: no-repeat;
  border: none;
  overflow: hidden;
  outline: none;
  padding: 1rem;
  opacity: 0.5;
  transition: 0.3s;

  &:hover {
    opacity: 1;
  }

  ${({ theme }) => theme.mq.desktop} {
    font-size: 56px;
  }

  ::after {
    content: '\\00d7';
  }
`;

const PreviousLink = styled.button`
  left: 5%;
  top: 50%;
  transform: translate(0%, -50%);
  position: fixed;
  font-size: 36px;
  cursor: pointer;
  z-index: 9999;
  color: white;
  background-color: black;
  background-repeat: no-repeat;
  border: none;
  overflow: hidden;
  outline: none;
  padding: 1rem;
  opacity: 0.5;
  transition: 0.3s;

  &:hover {
    opacity: 1;
  }

  &:disabled {
    display: none;
  }

  ${({ theme }) => theme.mq.desktop} {
    padding: 2rem;
  }
`;

const NextLink = styled.button`
  right: 5%;
  top: 50%;
  transform: translate(0%, -50%);
  position: fixed;
  font-size: 36px;
  cursor: pointer;
  z-index: 9999;
  color: white;
  background-color: black;
  background-repeat: no-repeat;
  border: none;
  overflow: hidden;
  outline: none;
  padding: 1rem;
  opacity: 0.5;
  transition: 0.3s;

  &:hover {
    opacity: 1;
  }

  &:disabled {
    display: none;
  }

  ${({ theme }) => theme.mq.desktop} {
    padding: 2rem;
  }
`;

const CaptionWrapper = styled.div`
  bottom: 2%;
  left: 2%;
  position: fixed;
`;

const StyledCaptionTitle = styled.figcaption`
  color: white;
  font-family: 'Dancing Script', cursive;
  overflow-wrap: break-word;

  a {
    color: white;
  }
`;

function SingleImage({ image }) {
  return (
    <SingleImageWrapper>
      <StyledImage image={image.image.asset.gatsbyImageData} alt={image.image.asset.id} />
    </SingleImageWrapper>
  );
}

export default function ImagesList({ images }) {
  const [showLightbox, setShowLightbox] = useState(false);
  const [selectedImage, setSelectedImage] = useState(0);
  
  const handleClick = (e, index) => {
    e.preventDefault();
    setShowLightbox(!showLightbox);
    setSelectedImage(index);
  };

  const closeModal = () => {
    setShowLightbox(false);
  };

  const goBack = () => {
    setSelectedImage(selectedImage - 1);
  };

  const goForward = () => {
    setSelectedImage(selectedImage + 1);
  };

  useEffect(() => {
    const handleKeyUp = (e) => {
      e.preventDefault();

      const { keyCode } = e;
      if (showLightbox) {
        if (keyCode === 37) {
          // Left Arrow Key
          if (selectedImage > 0) {
            setSelectedImage(selectedImage - 1);
          }
        }
        if (keyCode === 39) {
          // Right Arrow Key
          if (images && selectedImage < images.length - 1) {
            setSelectedImage(selectedImage + 1);
          }
        }
        if (keyCode === 27) {
          // Escape key
          setShowLightbox(false);
        }
      }
    };

    window.addEventListener('keyup', handleKeyUp, false);

    return () => {
      window.removeEventListener('keyup', handleKeyUp, false);
    };
  }, [showLightbox, selectedImage]);

  return <>
    <ImageGridStyles>
      {images.map((image, i) => {
        if (image !== undefined) {
          return (
            <SingleImageLink
              key={image.id}
              href={image.image.asset.gatsbyImageData.src}
              onClick={(e) => handleClick(e, i)}
            >
              <SingleImage image={image} />
            </SingleImageLink>
          );
        }
        return <></>;
      })}
    </ImageGridStyles>
    <LightboxModal visible={showLightbox}>
      <LightboxContent>
        <PreviousLink onClick={goBack} disabled={selectedImage === 0}>
          &#10094;
        </PreviousLink>
        <GatsbyImage
          image={images.length && images[selectedImage].image.asset.gatsbyImageData}
          alt={images.length && images[selectedImage].image.asset.id} />
        {images.length && images[selectedImage].name ? (
          <CaptionWrapper>
            <StyledCaptionTitle>
              Fotografia:{' '}
              <a href={images[selectedImage].name}>
                {images[selectedImage].name}
              </a>
            </StyledCaptionTitle>
          </CaptionWrapper>
        ) : (
          ''
        )}
        <CloseLink onClick={closeModal} />
        <NextLink
          onClick={goForward}
          disabled={selectedImage === images.length - 1}
        >
          &#10095;
        </NextLink>
      </LightboxContent>
    </LightboxModal>
  </>;
}
